import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {  RouterModule } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { TopNavViewmodel } from '../../models/view/top-nav.viewmodel';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
})
export class TopNavComponent implements OnInit {
  protected _commonService: CommonService;
  viewModel: TopNavViewmodel;

  @ViewChild('stickyMenu') stickyMenu!: ElementRef;

  constructor(
    private commonService: CommonService
  ) {
    this._commonService = commonService;
    this.viewModel = new TopNavViewmodel();
  }


  async ngOnInit() {

  }

  ngAfterViewInit() {
    this._commonService.layoutViewModel.elementPosition = this.stickyMenu.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', [])

  onWindowScroll() {
    if (window.scrollY > 90) {
      this._commonService.layoutViewModel.stickyMenu = true;
    } else {
      this._commonService.layoutViewModel.stickyMenu = false;
    }
  }


  toggleDropdown(event: any) {
    (event as Event).stopPropagation();
    switch (event.target.id) {
      case "solution-engineering":
        this._commonService.layoutViewModel.strategy = "servicesListDropdown";
        this._commonService.layoutViewModel.projectManagement = "servicesListDropdown";
        this._commonService.layoutViewModel.microsoft365 = "servicesListDropdown";
        this._commonService.layoutViewModel.cyberSecurity = "servicesListDropdown";
        this._commonService.layoutViewModel.softwareMaintenance = "servicesListDropdown";
        this._commonService.layoutViewModel.dataDriven = "servicesListDropdown";
        this._commonService.layoutViewModel.solutionEngineering = "toggleSolutionEngineering";
        break;

      case "strategy":
        this._commonService.layoutViewModel.solutionEngineering = "servicesListDropdown";
        this._commonService.layoutViewModel.projectManagement = "servicesListDropdown";
        this._commonService.layoutViewModel.microsoft365 = "servicesListDropdown";
        this._commonService.layoutViewModel.cyberSecurity = "servicesListDropdown";
        this._commonService.layoutViewModel.softwareMaintenance = "servicesListDropdown";
        this._commonService.layoutViewModel.dataDriven = "servicesListDropdown";
        this._commonService.layoutViewModel.strategy = "toggleStrategy";
        break;

      case "project-management":
        this._commonService.layoutViewModel.solutionEngineering = "servicesListDropdown";
        this._commonService.layoutViewModel.microsoft365 = "servicesListDropdown";
        this._commonService.layoutViewModel.cyberSecurity = "servicesListDropdown";
        this._commonService.layoutViewModel.softwareMaintenance = "servicesListDropdown";
        this._commonService.layoutViewModel.dataDriven = "servicesListDropdown";
        this._commonService.layoutViewModel.strategy = "servicesListDropdown";
        this._commonService.layoutViewModel.projectManagement = "toggleProjectManagement";
        break;

      case "microsoft365":
        this._commonService.layoutViewModel.solutionEngineering = "servicesListDropdown";
        this._commonService.layoutViewModel.cyberSecurity = "servicesListDropdown";
        this._commonService.layoutViewModel.softwareMaintenance = "servicesListDropdown";
        this._commonService.layoutViewModel.dataDriven = "servicesListDropdown";
        this._commonService.layoutViewModel.strategy = "servicesListDropdown";
        this._commonService.layoutViewModel.projectManagement = "servicesListDropdown";
        this._commonService.layoutViewModel.microsoft365 = "toggleMicrosoft365";
        break;

      case "cyber-security":
        this._commonService.layoutViewModel.solutionEngineering = "servicesListDropdown";
        this._commonService.layoutViewModel.softwareMaintenance = "servicesListDropdown";
        this._commonService.layoutViewModel.dataDriven = "servicesListDropdown";
        this._commonService.layoutViewModel.strategy = "servicesListDropdown";
        this._commonService.layoutViewModel.projectManagement = "servicesListDropdown";
        this._commonService.layoutViewModel.microsoft365 = "servicesListDropdown";
        this._commonService.layoutViewModel.cyberSecurity = "toggleCyberSecurity";
        break;

      case "software-maintenance":
        this._commonService.layoutViewModel.solutionEngineering = "servicesListDropdown";
        this._commonService.layoutViewModel.dataDriven = "servicesListDropdown";
        this._commonService.layoutViewModel.strategy = "servicesListDropdown";
        this._commonService.layoutViewModel.projectManagement = "servicesListDropdown";
        this._commonService.layoutViewModel.microsoft365 = "servicesListDropdown";
        this._commonService.layoutViewModel.cyberSecurity = "servicesListDropdown";
        this._commonService.layoutViewModel.softwareMaintenance = "toggleSoftwareMaintenance";
        break;

      case "data-driven":
        this._commonService.layoutViewModel.solutionEngineering = "servicesListDropdown";
        this._commonService.layoutViewModel.strategy = "servicesListDropdown";
        this._commonService.layoutViewModel.projectManagement = "servicesListDropdown";
        this._commonService.layoutViewModel.microsoft365 = "servicesListDropdown";
        this._commonService.layoutViewModel.cyberSecurity = "servicesListDropdown";
        this._commonService.layoutViewModel.softwareMaintenance = "servicesListDropdown";
        this._commonService.layoutViewModel.dataDriven = "toggleDataDriven";
        break;

      default:
        [
          this._commonService.layoutViewModel.solutionEngineering,
          this._commonService.layoutViewModel.strategy,
          this._commonService.layoutViewModel.projectManagement,
          this._commonService.layoutViewModel.microsoft365,
          this._commonService.layoutViewModel.cyberSecurity,
          this._commonService.layoutViewModel.softwareMaintenance,
          this._commonService.layoutViewModel.dataDriven
        ] = new Array(7).fill("servicesListDropdown");
        break;
    }

  }


}
