<footer class="footer_area footer_area_five f_bg">

    <div class="separator">
        <span>
            <i class="bi bi-square-fill"></i>
        </span>
    </div>


    <div class="container-fluid footer_top">
        <div class="row">

            <div class="col-lg-3 col-md-6 col-sm-6 col-12 text-lg-left text-md-left text-sm-left brandLogo">
                <a class="f-logo">
                    <img src="/assets/top-nav/renoLogoBlack.webp" alt="logo">
                </a>
                <p class="f_500 f_p f_size_15 mb-2 mt-1 mx-1 l_height14 ">
                    <span class="d-block footer_Tagline">
                        Your premier IT partner in the USA. Harnessing innovation for business success. Discover
                        tailored solutions and unmatched expertise with Reno Softwares.
                    </span>
                </p>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="row ">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6 ">
                        <div class="f_widget about-widget">
                            <ul class="list-unstyled f_list footer_links">
                                <li class="footer_links_list_Items"><a routerLink="/home"> Home </a></li>
                                <li class="footer_links_list_Items"><a routerLink="/products"> Products </a></li>
                                <li class="footer_links_list_Items"><a routerLink="/projects"> Projects </a></li>
                                <li class="footer_links_list_Items"><a routerLink="/services"> Services </a></li>
                                <li class="footer_links_list_Items"><a routerLink="/contact"> Contact</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6 ">
                        <div class="f_widget about-widget">
                            <ul class="list-unstyled f_list footer_links">
                                <li class="footer_links_list_Items"><a routerLink="/about"> About Us</a></li>
                                <li class="footer_links_list_Items"><a routerLink="/careers"> Careers</a></li>
                                <!-- <li class="footer_links_list_Items"><a routerLink="/home"> Blogs </a></li>
                                <li class="footer_links_list_Items"><a routerLink="/projects"> T&C </a></li>
                                <li class="footer_links_list_Items"><a routerLink="/all-products"> Privacy Policy </a></li>         -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 col-12 ">

                <div class="f_widget">
                    <img src="/assets/footer-images/contact.svg" alt="Not Found" loading="lazy" class="svgIcons">
                    <h3 class="f-title f_600 t_color f_size_18 d-inline-block ">
                        Let's Discuss Your Needs!
                    </h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="widget-wrap">
                                <p class="f_400 f_p f_size_15 mb-1 l_height34">
                                    <a href="tel:+1 904 638 9177" class="f_400 phoneNumber">
                                        <i class="bi bi-telephone"></i> +1 (904) 638-9177
                                    </a>
                                </p>
                                <p class="f_400 f_p f_size_14 mb-1 l_height34 email_To">
                                    <a href="mailto:sales@renosoftwares.com" class="f_400">
                                        <i class="bi bi-envelope-at"></i>
                                        sales&#64;renosoftwares.com
                                    </a>
                                </p>
                                <p class="f_400 f_p f_size_15 mb-1 l_height34 address_To">
                                    <a class="f_400">
                                        <i class="bi bi-geo-alt"></i>
                                        Los Angeles, CA 90069, United States
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="widget-wrap">
                                <p class="f_400 f_p f_size_15 mb-1 l_height34">
                                    <a href="tel:+1 510 629 1898" class="f_400 phoneNumber">
                                        <i class="bi bi-telephone"></i> +1 (510) 629-1898
                                    </a>
                                </p>
                                <p class="f_400 f_p f_size_15 mb-1 l_height34 email_To">
                                    <a href="mailto:info@renosoftwares.com" class="f_400">
                                        <i class="bi bi-envelope-at"></i>
                                        info&#64;renosoftwares.com
                                    </a>
                                </p>
                                <p class="f_400 f_p f_size_15 mb-1 l_height34 address_To">
                                    <a class="f_400">
                                        <i class="bi bi-geo-alt"></i>
                                        San Jose Blvd , Jacksonville, FL 32223, United States
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div class="footer_bottom">

        <div class="container-fluid">

            <div class="row">

                <div class="col-lg-4 col-md-4 col-sm-6 text-lg-start text-md-start text-sm-center text-center">
                    <p class="mb-0 f_400 copyright"> Copyright © 2023
                        <a href="#"> Reno Softwares </a>
                    </p>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-4">
                    <div class="f_social_icon_two text-center">
                        <a href="https://in.pinterest.com/renosoftwares/" target="_blank">
                            <i class="bi bi-pinterest"></i>
                        </a>
                        <a href="https://www.youtube.com/@renosoftwares" target="_blank">
                            <i class="bi bi-youtube"></i>
                        </a>
                        <a href="https://www.facebook.com/Reno-Softwares-108857695158902" target="_blank">
                            <i class="bi bi-facebook"></i>
                        </a>
                        <a href="https://www.instagram.com/renosoftwares/" target="_blank">
                            <i class="bi bi-instagram"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/renosoftwares/" target="_blank">
                            <i class="bi bi-linkedin"></i>
                        </a>
                        <a href="https://twitter.com/RenoSoftwares" target="_blank">
                            <i class="bi bi-twitter-x"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                    <!-- <ul class="list-unstyled f_menu text-center">
                        <li><a href="#">Terms of Use</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul> -->
                </div>

            </div>

        </div>

    </div>

</footer>