<div class="bg"></div>

<div id="preloader">

    <div id="ctn-preloader" class="ctn-preloader">

        <div class="animation-preloader">

            <div class="fadeinout">
                <img src="/assets/top-nav/reno-logo.webp" />
            </div>

            <!-- <div class="spinner"> </div> -->

            <div class="txt-loading">
                <span data-text-preloader="L" class="letters-loading">
                    L
                </span>
                <span data-text-preloader="o" class="letters-loading">
                    o
                </span>
                <span data-text-preloader="a" class="letters-loading">
                    a
                </span>
                <span data-text-preloader="d" class="letters-loading">
                    d
                </span>
                <span data-text-preloader="i" class="letters-loading">
                    i
                </span>
                <span data-text-preloader="n" class="letters-loading">
                    n
                </span>
                <span data-text-preloader="g" class="letters-loading">
                    g...
                </span>
                <!-- <span data-text-preloader="T" class="letters-loading">
                    T
                </span> -->
            </div>

            <!-- <p class="text-center"> Loading... </p> -->

        </div>

        <div class="loader">

            <div class="row">
                <div class="col-12 loader-section section-left">
                    <div class="bg"></div>
                </div>
                <!-- <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                </div> -->
            </div>

        </div>

    </div>

</div>