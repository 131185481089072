import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { MetaService } from './meta.service';

@Injectable({
  providedIn: 'root'
})
export class MetaResolver implements Resolve<any> {
  constructor(private metaService: MetaService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const meta = route.data;
    this.metaService.updateMeta(meta);
    return meta;
  }
}
