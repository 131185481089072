import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private meta: Meta, private title: Title, @Inject(PLATFORM_ID) private platformId: Object) { }

  updateMeta(metaData: {
    title?: string,
    description?: string,
    canonical?: string,
    schema?: string,
    ogTitle?: string,
    ogDescription?: string,
    ogUrl?: string,
    twitterTitle?: string,
    twitterDescription?: string,
    twitterUrl?: string
  }) {
    if (isPlatformBrowser(this.platformId)) {
      if (metaData.title) {
        this.title.setTitle(metaData.title);
      }
      if (metaData.description) {
        this.meta.updateTag({ name: 'description', content: metaData.description });
      }

      if (metaData.canonical) {
        const link = this.createOrUpdateTag<HTMLLinkElement>('link[rel="canonical"]', 'link', { rel: 'canonical', href: metaData.canonical });
        document.head.appendChild(link);
      }

      if (metaData.ogTitle) {
        this.meta.updateTag({ property: 'og:title', content: metaData.ogTitle });
      }

      if (metaData.ogDescription) {
        this.meta.updateTag({ property: 'og:description', content: metaData.ogDescription });
      }

      if (metaData.ogUrl) {
        this.meta.updateTag({ property: 'og:url', content: metaData.ogUrl });
      }
      if (metaData.twitterTitle) {
        this.meta.updateTag({ name: 'twitter:title', content: metaData.twitterTitle });
      }

      if (metaData.twitterDescription) {
        this.meta.updateTag({ name: 'twitter:description', content: metaData.twitterDescription });
      }

      if (metaData.twitterUrl) {
        this.meta.updateTag({ name: 'twitter:url', content: metaData.twitterUrl });
      }

      if (metaData.schema) {
        const script = this.createOrUpdateTag<HTMLScriptElement>('script[type="application/ld+json"]', 'script', { type: 'application/ld+json' });
        script.textContent = metaData.schema;
        document.head.appendChild(script);
      }
    }
  }

  private createOrUpdateTag<T extends HTMLElement>(selector: string, tag: string, attributes: { [key: string]: string }): T {
    let element = document.head.querySelector<T>(selector);
    if (!element) {
      element = document.createElement(tag) as T;
    }
    Object.keys(attributes).forEach(key => {
      element.setAttribute(key, attributes[key]);
    });
    return element;
  }
}
