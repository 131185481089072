import {  Routes } from "@angular/router";
import { MetaResolver } from "./services/meta.resolver.service";

export const routes: Routes = [

  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      images: [
        {
          loc: '/assets/home-images/cloudApplication.webp',
          caption: 'Reno Softwares Cloud Application service'
        },
        {
          loc: '/assets/home-images/projectManagement.webp',
          caption: 'Reno Softwares Project Management service'
        },
        {
          loc: '/assets/home-images/cyberSecurity.webp',
          caption: 'Reno Softwares Cyber Security service'
        },
        {
          loc: '/assets/home-images/customSoftwareSolution.webp',
          caption: 'Reno Softwares Custom Software Solutions service'
        }
      ],
      title: 'Reno Softwares - Expert in Web, Mobile, Cloud & Custom Software Solutions',
      description: 'Reno Softwares offers web development, project management, cybersecurity, mobile apps, cloud solutions and more. Partner with us for digital transformation and growth.',
      canonical: 'https://renosoftwares.com/home',
      ogTitle: 'Reno Softwares - Expert in Web, Mobile, Cloud & Custom Software Solutions',
      ogDescription: 'Reno Softwares offers web development, project management, cybersecurity, mobile apps, cloud solutions and more. Partner with us for digital transformation and growth.',
      ogUrl: 'https://renosoftwares.com/home',
      twitterTitle: 'Reno Softwares - Expert in Web, Mobile, Cloud & Custom Software Solutions',
      twitterDescription: 'Reno Softwares offers web development, project management, cybersecurity, mobile apps, cloud solutions and more. Partner with us for digital transformation and growth.',
      twitterUrl: 'https://renosoftwares.com/home',
      schema: `{
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "Reno Softwares",
          "url": "https://www.renosoftwares.com",
          "logo": "https://www.renosoftwares.com/assets/logo.jpg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-904-638-9177",
            "contactType": "Customer Service",
            "email": "sales@renosoftwares.com"
          },
          "sameAs": [
            "https://www.facebook.com/fb.renosoftwares",
            "https://www.linkedin.com/company/renosoftwares/",
            "https://in.pinterest.com/renosoftwares/",
            "https://www.youtube.com/@renosoftwares",
            "https://www.instagram.com/renosoftwares/",
            "https://x.com/RenoSoftwares"
          ],
          "description": "Reno Softwares is your premier IT partner. Harnessing innovation for business success, we also offer tailored solutions and unmatched expertise as a Microsoft partner.",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "USA",
            "addressCountry": "US"
          }
        }`
    }  
  },

  {
    path: 'about',
    loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/about-images/musaib.webp',
          caption: 'Reno Softwares Software Developer Musaib Gul'
        },
        {
          loc: '/assets/about-images/bilal.webp',
          caption: 'Reno Softwares Software developer Bilal Ahmad'
        }
      ],
      includeInSitemap: true,
      title: 'About Us - Reno Softwares',
      description: 'Discover how Reno Softwares is shaping the digital future with innovative software solutions, passionate experts, and a commitment to excellence.',
      canonical: 'https://renosoftwares.com/about',
      ogTitle: 'About Us - Reno Softwares',
      ogDescription: 'Discover how Reno Softwares is shaping the digital future with innovative software solutions, passionate experts, and a commitment to excellence.',
      ogUrl: 'https://renosoftwares.com/about',
      twitterTitle: 'About Us - Reno Softwares',
      twitterDescription: 'Discover how Reno Softwares is shaping the digital future with innovative software solutions, passionate experts, and a commitment to excellence.',
      twitterUrl: 'https://renosoftwares.com/about',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "About Us - Reno Softwares",
          "description": "Discover how Reno Softwares is shaping the digital future with innovative software solutions, passionate experts, and a commitment to excellence.",
          "url": "https://www.renosoftwares.com/about"
        }`
    }
  },

  {
    path: 'products',
    loadChildren: () => import('./components/products/all-products/all-products.module').then(m => m.AllProductsModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/products-images/API-Gateway.webp',
          caption: 'Reno Softwares Api Gateway product'
        },
        {
          loc: '/assets/products-images/units-and-metrics.webp',
          caption: 'Reno Softwares Units And Metrics mobile app'
        }
      ],
      includeInSitemap: true,
      title: 'Software Products - Reno Softwares',
      description: 'Explore software products designed to enhance productivity, creativity, and digital experience. Discover business solutions, utilities, and more.',
      canonical: 'https://renosoftwares.com/products',
      ogTitle: 'Software Products - Reno Softwares',
      ogDescription: 'Explore software products designed to enhance productivity, creativity, and digital experience. Discover business solutions, utilities, and more.',
      ogUrl: 'https://renosoftwares.com/products',
      twitterTitle: 'Software Products - Reno Softwares',
      twitterDescription: 'Explore software products designed to enhance productivity, creativity, and digital experience. Discover business solutions, utilities, and more.',
      twitterUrl: 'https://renosoftwares.com/products',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Software Products - Reno Softwares",
          "description": "Explore software products designed to enhance productivity, creativity, and digital experience. Discover business solutions, utilities, and more.",
          "url": "https://renosoftwares.com/products"
        }`
    }
  },

  {
    path: 'projects',
    loadChildren: () => import('./components/projects/projects.module').then(m => m.ProjectsModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      images: [
        {
          loc: '/assets/projects-images/gatehouseproject.webp',
          caption: 'Project GateHouse Solution'
        },
        {
          loc: '/assets/projects-images/gatehouseMobile.webp',
          caption: 'Project GateHouse Mobile App'
        },
        {
          loc: '/assets/projects-images/gatehouseGaurd.webp',
          caption: 'Project GateHouse Gaurd App'
        },
        {
          loc: '/assets/projects-images/flex.webp',
          caption: 'Project FlexAndPunch Fitness site'
        },
        {
          loc: '/assets/projects-images/flavourProject.webp',
          caption: 'Project FlavourOfKashmir Catering And calinery site'
        }
      ],
      title: 'Software Projects Showcase | Reno Softwares',
      description: 'Discover our diverse range of software projects that drive innovation and solve real-world challenges. Explore our practical solutions enhancing user experiences.',
      canonical: 'https://renosoftwares.com/projects',
      ogTitle: 'Software Projects Showcase | Reno Softwares',
      ogDescription: 'Discover our diverse range of software projects that drive innovation and solve real-world challenges. Explore our practical solutions enhancing user experiences.',
      ogUrl: 'https://renosoftwares.com/projects',
      twitterTitle: 'Software Projects Showcase | Reno Softwares',
      twitterDescription: 'Discover our diverse range of software projects that drive innovation and solve real-world challenges. Explore our practical solutions enhancing user experiences.',
      twitterUrl: 'https://renosoftwares.com/projects',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Software Projects Showcase | Reno Softwares",
          "description": "Discover our diverse range of software projects that drive innovation and solve real-world challenges. Explore our practical solutions enhancing user experiences.",
          "url": "https://renosoftwares.com/projects",
          "hasPart": {
            "@type": "ImageGallery",
            "name": "Project Images",
            "hasPart": [
              {
                "@type": "ImageObject",
                "contentUrl": "https://renosoftwares.com/assets/projects-images/gatehouseproject.webp",
                "name": "GateHouse Solutions",
                "description": "GateHouse Solutions security gate software."
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://renosoftwares.com/assets/projects-images/gatehouseMobile.webp",
                "name": "GateHouse Mobile App",
                "description": "GateHouse Mobile App for resident and visitor management."
              },
                {
                "@type": "ImageObject",
                "contentUrl": "https://renosoftwares.com/assets/projects-images/gatehouseGaurd.webp",
                "name": "GateHouse Guard App",
                "description": "GateHouse Guard App for visitor processing and gate control."
              },
                {
                "@type": "ImageObject",
                "contentUrl": "https://renosoftwares.com/assets/projects-images/flex.webp",
                "name": "FlexAndPunch",
                "description": "FlexAndPunch fitness website offering detailed information about fitness programs in Dubai."
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://renosoftwares.com/assets/projects-images/flavourProject.webp",
                "name": "Flavour Of Kashmir",
                "description": "Flavour Of Kashmir website showcasing homemade Kashmiri cuisine and catering services in California."
              }
            ]
          }
        }`
    }
  },

  {
    path: 'solution-engineering',
    loadChildren: () => import('./components/services/solution-engineeering/solution-engineeering.module').then(m => m.SolutionEngineeeringModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/solution-engineering-images/enterpriseSoftDev.webp',
          caption: 'Reno Softwares Enterprise Software Development service'
        },
        {
          loc: '/assets/solution-engineering-images/customSoftSolutions.webp',
          caption: 'Reno Softwares Custom Software Solutions service'
        },
        {
          loc: '/assets/solution-engineering-images/webDevelopment&Design.webp',
          caption: 'Reno Softwares Web Development & Design service'
        },
        {
          loc: '/assets/solution-engineering-images/mobileDev.webp',
          caption: 'Reno Softwares Mobile Development service'
        },
        {
          loc: '/assets/solution-engineering-images/cloudApps.webp',
          caption: 'Reno Softwares Cloud Application service'
        },
        {
          loc: '/assets/solution-engineering-images/UI_UX.webp',
          caption: 'Reno Softwares UI/UX service'
        }
      ],
      includeInSitemap: true,
      title: 'Solutions Engineering: Enterprise, Custom, Web & Mobile Development.',
      description: 'Explore our Solutions Engineering: Enterprise software, custom solutions, web and mobile development, UI/UX design, VOIP/PBX, cloud apps, and quality assurance.',
      canonical: 'https://renosoftwares.com/solution-engineering',
      ogTitle: 'Solutions Engineering: Enterprise, Custom, Web & Mobile Development.',
      ogDescription: 'Explore our Solutions Engineering: Enterprise software, custom solutions, web and mobile development, UI/UX design, VOIP/PBX, cloud apps, and quality assurance.',
      ogUrl: 'https://renosoftwares.com/solution-engineering',
      twitterTitle: 'Solutions Engineering: Enterprise, Custom, Web & Mobile Development.',
      twitterDescription: 'Explore our Solutions Engineering: Enterprise software, custom solutions, web and mobile development, UI/UX design, VOIP/PBX, cloud apps, and quality assurance.',
      twitterUrl: 'https://renosoftwares.com/solution-engineering',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Solutions Engineering: Enterprise, Custom, Web & Mobile Development.",
          "description": "Explore our Solutions Engineering: Enterprise software, custom solutions, web and mobile development, UI/UX design, VOIP/PBX, cloud apps, and quality assurance.",
          "url": "https://renosoftwares.com/solution-engineering"
        }`
    }
  },

  {
    path: 'strategy',
    loadChildren: () => import('./components/services/strategy/strategy.module').then(m => m.StrategyModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/strategy-images/research&Dev.webp',
          caption: 'Reno Softwares Research & Development service'
        },
        {
          loc: '/assets/strategy-images/TechnicalConsulting.webp',
          caption: 'Reno Softwares Technical Consulting service'
        },
        {
          loc: '/assets/strategy-images/customerExperience.webp',
          caption: 'Reno Softwares Customer Experience service'
        }
      ],
      includeInSitemap: true,
      title: 'Strategy & Consulting Services - Reno Softwares',
      description: 'Discover our strategy and consulting services: digital transformation, R&D, technical consulting, and enhancing customer experience to align with your business goals.',
      canonical: 'https://renosoftwares.com/strategy',
      ogTitle: 'Strategy & Consulting Services - Reno Softwares',
      ogDescription: 'Discover our strategy and consulting services: digital transformation, R&D, technical consulting, and enhancing customer experience to align with your business goals.',
      ogUrl: 'https://renosoftwares.com/strategy',
      twitterTitle: 'Strategy & Consulting Services - Reno Softwares',
      twitterDescription: 'Discover our strategy and consulting services: digital transformation, R&D, technical consulting, and enhancing customer experience to align with your business goals.',
      twitterUrl: 'https://renosoftwares.com/strategy',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Strategy & Consulting Services - Reno Softwares",
          "description": "Discover our strategy and consulting services: digital transformation, R&D, technical consulting, and enhancing customer experience to align with your business goals.",
          "url": "https://renosoftwares.com/strategy"
        }`
    }
  },

  {
    path: 'project-management',
    loadChildren: () => import('./components/services/project-management/project-management.module').then(m => m.ProjectManagementModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/project-management-images/budgeting&CostControl.webp',
          caption: 'Reno Softwares Budgeting & Cost Control service'
        },
        {
          loc: '/assets/project-management-images/planning&Resources.webp',
          caption: 'Reno Softwares Planning & Resources service'
        },
        {
          loc: '/assets/project-management-images/releaseManagement.webp',
          caption: 'Reno Softwares Release Management service'
        },
        {
          loc: '/assets/project-management-images/client&vendor.webp',
          caption: 'Reno Softwares Client & Vendor service'
        },
        {
          loc: '/assets/project-management-images/agile&scrum.webp',
          caption: 'Reno Softwares Agile & Scrum service'
        }
      ],
      includeInSitemap: true,
      title: 'Project Management Services - Reno Softwares',
      description: 'Explore our project management services: budgeting, planning, release management, client & vendor relations, and Agile & Scrum for successful outcomes.',
      canonical: 'https://renosoftwares.com/project-management',
      ogTitle: 'Project Management Services - Reno Softwares',
      ogDescription: 'Explore our project management services: budgeting, planning, release management, client & vendor relations, and Agile & Scrum for successful outcomes.',
      ogUrl: 'https://renosoftwares.com/project-management',
      twitterTitle: 'Project Management Services - Reno Softwares',
      twitterDescription: 'Explore our project management services: budgeting, planning, release management, client & vendor relations, and Agile & Scrum for successful outcomes.',
      twitterUrl: 'https://renosoftwares.com/project-management',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Project Management Services - Reno Softwares",
          "description": "Explore our project management services: budgeting, planning, release management, client & vendor relations, and Agile & Scrum for successful outcomes.",
          "url": "https://renosoftwares.com/project-management"
        }`
    }
  },

  {
    path: 'cyber-security',
    loadChildren: () => import('./components/services/cyber-security/cyber-security.module').then(m => m.CyberSecurityModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/cyber-security-images/network&CloudSecurity.webp',
          caption: 'Reno Softwares Network, Endpoint & Cloud security service'
        },
        {
          loc: '/assets/cyber-security-images/vulnerabilityManagement.webp',
          caption: 'Reno Softwares Vulnerability Management service'
        },
        {
          loc: '/assets/cyber-security-images/dataEncryption.webp',
          caption: 'Reno Softwares Data Encryption service'
        },
        {
          loc: '/assets/cyber-security-images/accessControl.webp',
          caption: 'Reno Softwares access control service'
        }
      ],
      includeInSitemap: true,
      title: 'Cyber Security & Data Protection | Reno Softwares',
      description: 'Cyber Security & Data Protection at Reno Softwares: network security, vulnerability management, data encryption, and access control to safeguard your data.',
      canonical: 'https://renosoftwares.com/cyber-security',
      ogTitle: 'Cyber Security & Data Protection | Reno Softwares',
      ogDescription: 'Cyber Security & Data Protection at Reno Softwares: network security, vulnerability management, data encryption, and access control to safeguard your data.',
      ogUrl: 'https://renosoftwares.com/cyber-security',
      twitterTitle: 'Cyber Security & Data Protection | Reno Softwares',
      twitterDescription: 'Cyber Security & Data Protection at Reno Softwares: network security, vulnerability management, data encryption, and access control to safeguard your data.',
      twitterUrl: 'https://renosoftwares.com/cyber-security',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Cyber Security & Data Protection | Reno Softwares",
          "description": "Cyber Security & Data Protection at Reno Softwares: network security, vulnerability management, data encryption, and access control to safeguard your data.",
          "url": "https://renosoftwares.com/cyber-security"
        }`
    }
  },

  {
    path: 'software-maintenance',
    loadChildren: () => import('./components/services/software-maintenance/software-maintenance.module').then(m => m.SoftwareMaintenanceModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/software-maintenance-images/PerformanceOptimization.webp',
          caption: 'Reno Softwares Performance Optimization service'
        },
        {
          loc: '/assets/software-maintenance-images/ThirdPartyIntegration.webp',
          caption: 'Reno Softwares Third-Party Integration service'
        },
        {
          loc: '/assets/software-maintenance-images/databaseMaintenance.webp',
          caption: 'Reno Softwares Database Maintenance service'
        }
      ],
      includeInSitemap: true,
      title: 'Software Maintenance & Upgrades | Reno Softwares',
      description: 'Optimize performance, update integrations, and maintain databases with Reno Softwares and upgrades for improved efficiency and security.',
      canonical: 'https://renosoftwares.com/software-maintenance',
      ogTitle: 'Software Maintenance & Upgrades | Reno Softwares',
      ogDescription: 'Optimize performance, update integrations, and maintain databases with Reno Softwares and upgrades for improved efficiency and security.',
      ogUrl: 'https://renosoftwares.com/software-maintenance',
      twitterTitle: 'Software Maintenance & Upgrades | Reno Softwares',
      twitterDescription: 'Optimize performance, update integrations, and maintain databases with Reno Softwares and upgrades for improved efficiency and security.',
      twitterUrl: 'https://renosoftwares.com/software-maintenance',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Software Maintenance & Upgrades | Reno Softwares",
          "description": "Optimize performance, update integrations, and maintain databases with Reno Softwares and upgrades for improved efficiency and security.",
          "url": "https://renosoftwares.com/software-maintenance"
        }`
    }
  },

  {
    path: 'data-driven-marketing',
    loadChildren: () => import('./components/services/data-driven-marketing/data-driven-marketing.module').then(m => m.DataDrivenMarketingModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/data-driven-marketing-images/dataAnalytics&BusinessIntelligence.webp',
          caption: 'Reno Softwares Data Analytics & Business Intelligence service'
        },
        {
          loc: '/assets/data-driven-marketing-images/SEO&DigitalMarketing.webp',
          caption: 'Reno Softwares SEO & Digital Marketing service'
        }
      ],
      includeInSitemap: true,
      title: 'Data-Driven Marketing Strategies | Reno Softwares',
      description: 'Enhance your marketing with data-driven strategies. Services include analytics, SEO, and digital marketing to optimize performance and engagement.',
      canonical: 'https://renosoftwares.com/data-driven-marketing',
      ogTitle: 'Data-Driven Marketing Strategies | Reno Softwares',
      ogDescription: 'Enhance your marketing with data-driven strategies. Services include analytics, SEO, and digital marketing to optimize performance and engagement.',
      ogUrl: 'https://renosoftwares.com/data-driven-marketing',
      twitterTitle: 'Data-Driven Marketing Strategies | Reno Softwares',
      twitterDescription: 'Enhance your marketing with data-driven strategies. Services include analytics, SEO, and digital marketing to optimize performance and engagement.',
      twitterUrl: 'https://renosoftwares.com/data-driven-marketing',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Data-Driven Marketing Strategies | Reno Softwares",
          "description": "Enhance your marketing with data-driven strategies. Services include analytics, SEO, and digital marketing to optimize performance and engagement.",
          "url": "https://renosoftwares.com/data-driven-marketing"
        }`
    }
  },

  {
    path: 'microsoft-365',
    loadChildren: () => import('./components/services/microsoft365/microsoft365.module').then(m => m.Microsoft365Module),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/microsoft-365-images/sharepoint.webp',
          caption: 'Reno Softwares SharePoint service'
        },
        {
          loc: '/assets/microsoft-365-images/microsoftTeams.webp',
          caption: 'Reno Softwares Microsoft Teams service'
        },
        {
          loc: '/assets/microsoft-365-images/licensing&SubscriptionManagement.webp',
          caption: 'Reno Softwares Licensing & Subscription Management service'
        },
        {
          loc: '/assets/microsoft-365-images/dataSecurity&ComplianceServices.webp',
          caption: 'Reno Softwares Data Security & Compliance Services service'
        },
        {
          loc: '/assets/microsoft-365-images/administration&Support.webp',
          caption: 'Reno Softwares Administration & Support Solutions service'
        },
        {
          loc: '/assets/microsoft-365-images/dataMigrationSolutions.webp',
          caption: 'Reno Softwares Data Migration Solutions service'
        },
        {
          loc: '/assets/microsoft-365-images/office-365.webp',
          caption: 'Reno Softwares Microsoft Office 365 service'
        },
        {
          loc: '/assets/microsoft-365-images/backup&recovery.webp',
          caption: 'Reno Softwares Backup & Recovery service'
        }
      ],
      includeInSitemap: true,
      title: 'Microsoft 365 Services | Reno Softwares',
      description: 'Explore our Microsoft 365 services: SharePoint solutions, Teams setup, licensing, data security, migration, and ongoing support.',
      canonical: 'https://renosoftwares.com/microsoft-365',
      ogTitle: 'Microsoft 365 Services | Reno Softwares',
      ogDescription: 'Explore our Microsoft 365 services: SharePoint solutions, Teams setup, licensing, data security, migration, and ongoing support.',
      ogUrl: 'https://renosoftwares.com/microsoft-365',
      twitterTitle: 'Microsoft 365 Services | Reno Softwares',
      twitterDescription: 'Explore our Microsoft 365 services: SharePoint solutions, Teams setup, licensing, data security, migration, and ongoing support.',
      twitterUrl: 'https://renosoftwares.com/microsoft-365',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Microsoft 365 Services | Reno Softwares",
          "description": "Explore our Microsoft 365 services: SharePoint solutions, Teams setup, licensing, data security, migration, and ongoing support.",
          "url": "https://renosoftwares.com/microsoft-365"
        }`
    }
  },

  {
    path: 'careers',
    loadChildren: () => import('./components/careers/careers.module').then(m => m.CareersModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      images: [
        {
          loc: '/assets/career-images/careers-banner.webp',
          caption: 'Reno Softwares Careers illustration'
        }
      ],
      includeInSitemap: true,
      title: 'Careers at Reno Softwares | Join Our Innovative Team',
      description: 'Explore career opportunities at Reno Softwares. Submit your profile to join our dynamic team and be part of cutting-edge software solutions. Shape the future with us.',
      canonical: 'https://renosoftwares.com/careers',
      ogTitle: 'Careers at Reno Softwares | Join Our Innovative Team',
      ogDescription: 'Explore career opportunities at Reno Softwares. Submit your profile to join our dynamic team and be part of cutting-edge software solutions. Shape the future with us.',
      ogUrl: 'https://renosoftwares.com/careers',
      twitterTitle: 'Careers at Reno Softwares | Join Our Innovative Team',
      twitterDescription: 'Explore career opportunities at Reno Softwares. Submit your profile to join our dynamic team and be part of cutting-edge software solutions. Shape the future with us.',
      twitterUrl: 'https://renosoftwares.com/careers',
      schema: `{
          "@context": "https://schema.org",
          "@type": "JobPosting",
          "title": "Careers at Reno Softwares",
          "description": "Join our innovative team at Reno Softwares. Submit your profile for future opportunities and contribute to pioneering software solutions.",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "Reno Softwares",
            "url": "https://renosoftwares.com/"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Karan Nagar",
              "addressLocality": "Srinagar",
              "addressRegion": "J&K",
              "addressCountry": "IN"
            }
          },
          "applicationContact": {
            "@type": "ContactPoint",
            "contactType": "Human Resources",
            "email": "career.renosoftwares@gmail.com",
            "telephone": "+91-9622753433"
          },
          "url": "https://renosoftwares.com/careers"
        }`
    }
  },

  {
    path: 'careers/apply/:Id',
    loadChildren: () => import('./components/job-application/job-application.module').then(m => m.JobApplicationModule)
  },

  {
    path: 'contact',
    loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      title: 'Contact Reno Softwares | Get in Touch with Our Team',
      description: 'Reach out to Reno Softwares for inquiries and project information. Contact us via phone, email, or by filling out the form on our contact page.',
      canonical: 'https://renosoftwares.com/contact',
      ogTitle: 'Contact Reno Softwares | Get in Touch with Our Team',
      ogDescription: 'Reach out to Reno Softwares for inquiries and project information. Contact us via phone, email, or by filling out the form on our contact page.',
      ogUrl: 'https://renosoftwares.com/contact',
      twitterTitle: 'Contact Reno Softwares | Get in Touch with Our Team',
      twitterDescription: 'Reach out to Reno Softwares for inquiries and project information. Contact us via phone, email, or by filling out the form on our contact page.',
      twitterUrl: 'https://renosoftwares.com/contact',
      schema: `{
          "@context": "https://schema.org",
          "@type": "ContactPage",
          "name": "Contact Reno Softwares",
          "description": "Contact Reno Softwares to learn more about our projects. Reach us via phone, email, or by filling out the contact form.",
          "url": "https://renosoftwares.com/contact",
          "contactPoint": [
            {
              "@type": "ContactPoint",
              "contactType": "Customer Service",
              "telephone": "+1-904-638-9177",
              "email": "sales@renosoftwares.com",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Los Angeles, CA 90069",
                "addressLocality": "Los Angeles",
                "addressRegion": "CA",
                "addressCountry": "US"
              },
              "areaServed": "US"
            },
            {
              "@type": "ContactPoint",
              "contactType": "Customer Service",
              "telephone": "+1-510-629-1898",
              "email": "info@renosoftwares.com",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "San Jose Blvd, Jacksonville, FL 32223",
                "addressLocality": "Jacksonville",
                "addressRegion": "FL",
                "addressCountry": "US"
              },
              "areaServed": "US"
            },
            {
              "@type": "ContactPoint",
              "contactType": "Customer Service",
              "telephone": "+91-9622753433",
              "email": "contact@renosoftwares.com",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Karan Nagar",
                "addressLocality": "Srinagar",
                "addressRegion": "J&K",
                "addressCountry": "IN"
              },
              "areaServed": "IN"
            }
          ]
        }`
    }
  },

  // {
  //   path: 'blog/:Id',
  //   loadChildren: () => import('./components/blog/blog.module').then(m => m.BlogModule)
  // },

  // {
  //   path: 'blogs',
  //   loadChildren: () => import('./components/blog-list/blog-list.module').then(m => m.BlogListModule),
  //    resolve: {
  //     meta: MetaResolver
  //   },
  //   data: {
  //     images: [
  //       {
  //         loc: '/assets/blog-images/blogs.webp',
  //         caption: 'Reno Softwares Blog'
  //       }
  //     ],
  //     includeInSitemap: true,
  //     title: 'Reno Softwares Blog | Technology Trends & Innovation Insights',
  //     description: 'Explore the Reno Softwares Blog for the latest technology trends, in-depth analysis, and practical advice from industry experts',
  //     canonical: 'https://renosoftwares.com/blogs',
  //     ogTitle: 'Reno Softwares Blog | Technology Trends & Innovation Insights',
  //     ogDescription: 'Explore the Reno Softwares Blog for the latest technology trends, in-depth analysis, and practical advice from industry experts',
  //     ogUrl: 'https://renosoftwares.com/blogs',
  //     twitterTitle: 'Reno Softwares Blog | Technology Trends & Innovation Insights',
  //     twitterDescription: 'Explore the Reno Softwares Blog for the latest technology trends, in-depth analysis, and practical advice from industry experts',
  //     twitterUrl: 'https://renosoftwares.com/blogs',
  //     schema: `{
  //         "@context": "https://schema.org",
  //         "@type": "WebPage",
  //         "name": "Reno Softwares Blog | Technology Trends & Innovation Insights",
  //         "description": "Explore the Reno Softwares Blog for the latest technology trends, in-depth analysis, and practical advice from industry experts",
  //         "url": "https://renosoftwares.com/blogs"
  //       }`
  //   }
  // },

  {
    path: 'forgot-password',
    loadChildren: () => import('./internal-components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },

   {
    path: 'products/apigateway',
    loadChildren: () => import('./components/products/api-gateway/api-gateway.module').then(m => m.ApiGatewayModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      title: 'Custom API Gateway Solutions | Reno Softwares',
      description: 'Centralize, secure, and optimize your API management with our custom API Gateway solutions tailored to meet your unique business needs.',
      canonical: 'https://renosoftwares.com/products/apigateway',
      ogTitle: 'Custom API Gateway Solutions | Reno Softwares',
      ogDescription: 'Centralize, secure, and optimize your API management with our custom API Gateway solutions tailored to meet your unique business needs.',
      ogUrl: 'https://renosoftwares.com/products/apigateway',
      twitterTitle: 'Custom API Gateway Solutions | Reno Softwares',
      twitterDescription: 'Centralize, secure, and optimize your API management with our custom API Gateway solutions tailored to meet your unique business needs.',
      twitterUrl: 'https://renosoftwares.com/products/apigateway',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Custom API Gateway Solutions | Reno Softwares",
          "description": "Centralize, secure, and optimize your API management with our custom API Gateway solutions tailored to meet your unique business needs.",
          "url": "https://renosoftwares.com/products/apigateway"
        }`
    }
  },

  {
    path: 'products/dataraptor',
    loadChildren: () => import('./components/products/data-raptor/data-raptor.module').then(m => m.DataRaptorModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      title: 'DataRaptor: Database Management and Migration Tool | Reno Softwares',
      description: 'Streamline your database tasks with DataRaptor. Compare, migrate, and manage data seamlessly with our intuitive desktop application.',
      canonical: 'https://renosoftwares.com/products/dataraptor',
      ogTitle: 'DataRaptor: Database Management and Migration Tool | Reno Softwares',
      ogDescription: 'Streamline your database tasks with DataRaptor. Compare, migrate, and manage data seamlessly with our intuitive desktop application.',
      ogUrl: 'https://renosoftwares.com/products/dataraptor',
      twitterTitle: 'DataRaptor: Database Management and Migration Tool | Reno Softwares',
      twitterDescription: 'Streamline your database tasks with DataRaptor. Compare, migrate, and manage data seamlessly with our intuitive desktop application.',
      twitterUrl: 'https://renosoftwares.com/products/dataraptor',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "DataRaptor: Database Management and Migration Tool | Reno Softwares",
          "description": "Streamline your database tasks with DataRaptor. Compare, migrate, and manage data seamlessly with our intuitive desktop application.",
          "url": "https://renosoftwares.com/products/dataraptor"
        }`
    }
  },
  {
    path: 'products/units&metrics',
    loadChildren: () => import('./components/products/units-and-metrics/units-and-metrics.module').then(m => m.UnitsAndMetricsModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      title: 'Units & Metrics: Smart Unit Conversion | Reno Softwares',
      description: 'Quickly convert units with Units & Metrics by Reno Softwares. Accurate and efficient for all your measurement needs.',
      canonical: 'https://renosoftwares.com/products/units&metrics',
      ogTitle: 'Units & Metrics: Smart Unit Conversion | Reno Softwares',
      ogDescription: 'Quickly convert units with Units & Metrics by Reno Softwares. Accurate and efficient for all your measurement needs.',
      ogUrl: 'https://renosoftwares.com/products/units&metrics',
      twitterTitle: 'Units & Metrics: Smart Unit Conversion | Reno Softwares',
      twitterDescription: 'Quickly convert units with Units & Metrics by Reno Softwares. Accurate and efficient for all your measurement needs.',
      twitterUrl: 'https://renosoftwares.com/products/units&metrics',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Units & Metrics: Smart Unit Conversion | Reno Softwares",
          "description": "Quickly convert units with Units & Metrics by Reno Softwares. Accurate and efficient for all your measurement needs.",
          "url": "https://renosoftwares.com/products/units&metrics"
        }`
    }
  },
  {
    path: 'products/levelcompass',
    loadChildren: () => import('./components/products/level-compass/level-compass.module').then(m => m.LevelCompassModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      title: 'Level Compass: Accurate Navigation & Level Measurement | Reno Softwares',
      description: 'Navigate with precision using Level Compass by Reno Softwares. A reliable tool for accurate navigation and level measurement.',
      canonical: 'https://renosoftwares.com/products/levelcompass',
      ogTitle: 'Level Compass: Accurate Navigation & Level Measurement | Reno Softwares',
      ogDescription: 'Navigate with precision using Level Compass by Reno Softwares. A reliable tool for accurate navigation and level measurement.',
      ogUrl: 'https://renosoftwares.com/products/levelcompass',
      twitterTitle: 'Level Compass: Accurate Navigation & Level Measurement | Reno Softwares',
      twitterDescription: 'Navigate with precision using Level Compass by Reno Softwares. A reliable tool for accurate navigation and level measurement.',
      twitterUrl: 'https://renosoftwares.com/products/levelcompass',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Level Compass: Accurate Navigation & Level Measurement | Reno Softwares",
          "description": "Navigate with precision using Level Compass by Reno Softwares. A reliable tool for accurate navigation and level measurement.",
          "url": "https://renosoftwares.com/products/levelcompass"
      }`
  }
  },
  {
    path: 'products/qrscancraft',
    loadChildren: () => import('./components/products/qr-scan-craft/qr-scan-scan.module').then(m => m.QrScanCraftModule),
    resolve: {
      meta: MetaResolver
    },
    data: {
      includeInSitemap: true,
      title: 'QR Scan Craft: Reliable QR & Barcode scan | Reno Softwares',
      description: 'Easily scan and generate QR and barcodes with QR Scan Craft by Reno Softwares. Your reliable tool for all QR and barcode formats.',
      canonical: 'https://renosoftwares.com/products/qrscancraft',
      ogTitle: 'QR Scan Craft: Reliable QR & Barcode scan | Reno Softwares',
      ogDescription: 'Easily scan and generate QR and barcodes with QR Scan Craft by Reno Softwares. Your reliable tool for all QR and barcode formats.',
      ogUrl: 'https://renosoftwares.com/products/qrscancraft',
      twitterTitle: 'QR Scan Craft: Reliable QR & Barcode scan | Reno Softwares',
      twitterDescription: 'Easily scan and generate QR and barcodes with QR Scan Craft by Reno Softwares. Your reliable tool for all QR and barcode formats.',
      twitterUrl: 'https://renosoftwares.com/products/qrscancraft',
      schema: `{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "QR Scan Craft: Reliable QR & Barcode scan | Reno Softwares",
          "description": "Easily scan and generate QR and barcodes with QR Scan Craft by Reno Softwares. Your reliable tool for all QR and barcode formats.",
          "url": "https://renosoftwares.com/products/qrscancraft"
      }`
  }
  },

  {
    path: 'products/planmydoctor',
    loadChildren: () => import('./components/products/plan-my-doctor/plan-my-doctor.module').then(m => m.PlanMyDoctorModule)
  },

  {
    path: 'products/etender',
    loadChildren: () => import('./components/products/e-tender/e-tender.module').then(m => m.ETenderModule)
  },

  {
    path: 'products/coin',
    loadChildren: () => import('./components/products/coin-management/coin-management.module').then(m => m.CoinManagementModule)
  },

  {
    path: 'sign-up',
    loadChildren: () => import('./internal-components/sign-up/sign-up.module').then(m => m.SignUpModule)
  },

  {
    path: 'sign-in',
    loadChildren: () => import('./internal-components/sign-in/sign-in.module').then(m => m.SignInModule)
  },

  { path: '', redirectTo: 'home', pathMatch: 'full', },

  { path: '**', redirectTo: 'home', pathMatch: 'full', },

];
